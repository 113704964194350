
/* General */
.container {
    margin: 0 35px;
}

.modal-list-button {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.font-size-normal {
    font-size: large;
}

.font-size-small {
    font-size: medium;
}


.content-center {
    margin: auto;
}

.header-title {
    margin-top: 35px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: 25px;
}

.header-subtitle {
    margin-top: 5px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    font-size: 22px;
    color: #707070;
}

.list-category-font-size {
    font-size: 20px;
}

.state-icon {
    width: 200px;
    margin-top: 25%;
    margin-bottom: 25%;
    margin-left: auto;
    margin-right: auto;
} 

.clickable-text {
    cursor: pointer;
}

.clickable-item {
    --border-color: rgba(4, 159, 173, 0.2);
}

.toolbar-img {
    width: 50px;
    margin-right: 12px;
}


/* Verification Success View */
.verification-success-confirm-button {
    margin-top: 35px;
    margin-bottom: 35px;
    vertical-align: bottom;
}


/* Trust Chain Modal */
.trust-chain-modal-entity-title {
    margin-top: 25px;
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: fit-content;
}


/* Input Data View */
.input-data-submit-data {
    margin-top: 20px;
}


/* Password Modal */
.password-modal-text {
    margin-top: 50px;
    margin-left: 15px;
}

.password-modal-buttons {
    margin-top: 20px;
    float: right;
}

.password-modal-button {
    padding-left: 10px;
    padding-right: 10px;
}

.password-modal-error-item {
    --border-color: #d33939;
}
